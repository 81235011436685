import React, { useState } from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import TrainedDogsPage from '../components/TrainedDogs'
import galerie1 from '../assets/images/galerie1.webp'
import galerie2 from '../assets/images/galerie2.webp'
import galerie3 from '../assets/images/galerie3.webp'
import galerie4 from '../assets/images/galerie4.webp'
import galerie5 from '../assets/images/galerie5.webp'
import pourquoi from '../assets/images/pourquoiDMC.webp'

const ServicesPage = props => {

  //ajout de className pour le css
  const [nameOfClass, setNameOfClass] = useState('')

  return (
    <>
     <header>
        <Navbar className={nameOfClass} />
      </header>
      <div className='services'>
        <h2>{props.h2}</h2>
        <h3>Pourquoi choisir <span className='bold'>DMC</span> ?</h3>
        <p id="textePourquoi">Nous nous occuperont de votre chien, quel que soit son <span className='bold'>caractère</span> ou son  <span className='bold'>âge</span>. Que vous ayez un  <span className='bold'>gros</span> ou un  <span className='bold'>petit chien</span>, un chien  <span className='bold'>calme</span> ou <span className='bold'>agressif</span>, nous pouvons vous aider !</p>
        <div id='listingPourquoi'>
          <ul>
            <li><i class="fa-solid fa-shield-dog" alt="icone_liste_puces"></i>&nbsp;&nbsp;Redressement comportemental</li>
            <li><i class="fa-solid fa-shield-dog" alt="icone_liste_puces"></i>&nbsp;&nbsp;Éducation de chiots</li>
            <li><i class="fa-solid fa-shield-dog" alt="icone_liste_puces"></i>&nbsp;&nbsp;Amélioration de l'obéissance</li>
            <li><i class="fa-solid fa-shield-dog" alt="icone_liste_puces"></i>&nbsp;&nbsp;Remise en forme</li>
            <li><i class="fa-solid fa-shield-dog" alt="icone_liste_puces"></i>&nbsp;&nbsp;Gardiennage</li>
          </ul>
          <img src={pourquoi} alt="chiot_illustration" id="chiot_illustration"></img>
        </div>
        <div className='paragraphe3'>
          <p>Après avoir <span className='bold'>échangé</span> avec vous sur vos souhaits et sur les <span className='bold'>besoins</span> du chien, nous convenons de ce qu'il y a de <span className='bold'>mieux</span> pour votre animal.</p>
          <p><span className='bold'>Chaque chien est différent</span>, si vous avez des <span className='bold'>demandes spécifiques</span>, n'hésitez pas à nous contacter par téléphone, mail, ou via les réseaux sociaux.</p>
        </div>
        <div className='iconesServices'>
          <a
            href="https://www.facebook.com/maxime.desmettre"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-brands fa-facebook"></i>
          </a>
          <a
            href="https://www.instagram.com/maximedesmettre/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a
            href="mailto:pierrocarlier@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-solid fa-envelope"></i>
          </a>
          <a href="tel:+33643831004" target="_blank" rel="noopener noreferrer">
            <i className="fa-solid fa-phone"></i>
          </a>
        </div>
        <div className='galerieImagesServices'>
          <img className='imgGalerie' src={galerie1} alt="image1_galerie"></img>
          <img className='imgGalerie' src={galerie2} alt="image2_galerie"></img>
          <img className='imgGalerie' src={galerie3} alt="image3_galerie"></img>
          <img className='imgGalerie' src={galerie4} alt="image4_galerie"></img>
          <img className='imgGalerie' src={galerie5} alt="image5_galerie"></img>
        </div>
      </div>
      <TrainedDogsPage />
      <footer>
        <Footer h2={props.h2} />
      </footer>
    </>
  )
}

export default ServicesPage