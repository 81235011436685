import React, { useState } from 'react'
import { Link } from "react-router-dom";
import Footer from '../components/Footer'
import Navbar from '../components/Navbar';

const HomePage = props => {

   //ajout de className pour le css
   const [nameOfClass, setNameOfClass] = useState('')

  return (
    <>
    <header className='headerHome'>
        <Navbar className={nameOfClass} />
      </header>
      <div className='homePageTop'>
        <main>
          <h1>Services de<br /> dressage<br /> canin</h1>
        </main>
      <nav className='homepageNav'>
        <Link to="/about">À propos</Link>
        <Link to="/services">Services</Link>
        <Link to="/avis">Avis</Link>
        <Link to="/reserver">Gardiennage</Link>
        <Link to="/tips">Quelques conseils</Link>
      </nav>
      </div>
      <footer>
        <Footer h2={props.h2} />
      </footer>
    </>
  )
}

export default HomePage