import axios from "axios";
import React, { useEffect, useState } from "react";
import env from "react-dotenv";

const Experiences = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${env.REACT_APP_API_URL}educations/all`
      );
      setData(response.data);
    };
    fetchData();
    setIsLoading(false);
  }, []);

  return (
    <div className="aboutExperiences">
      <p>
        <span className="chiffres">10</span>
        <span className="texts">ans d'éducation canine</span>
      </p>
      <p>
        <span className="chiffres">{10 + data.length}</span>
        <span className="texts second">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;chiens dressés
        </span>
      </p>
      <p className="texts" id="concoursContainer">
        <span className="chiffres concours">
          concours
          <br />
        </span>
        de dressage canin
      </p>
    </div>
  );
};

export default Experiences;
