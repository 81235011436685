import React from "react";
import { Link } from "react-router-dom";
import { useState, Fragment } from "react";
import logoDMCMobile from "../assets/images/logoDMCMobile.webp"
import logoDMC from "../assets/images/logoDMC.webp"

const Navbar = (props)=> {

  // Stocke l'état du input qui gère l'affichage du menu dans un state
  const [iconBurgerIsOpen, setIconBurgerIsOpen] = useState(false)  
  
  //Si il est cliqué, le state change
  function handleClick(){
    document.getElementById('burgerIcon').checked? setIconBurgerIsOpen(true) : setIconBurgerIsOpen(false)
  }

  // En fonction de l'état, un icone est affiché
  const iconMenuBurger = iconBurgerIsOpen ? <i className="fa-solid fa-xmark"></i> : <i className="fa-solid fa-bars"></i>
  const logoHeader = !iconBurgerIsOpen ? <Link to="/" id="logoHeader" ><img src={logoDMCMobile} alt="logo_dmc" /></Link> : <img src={logoDMCMobile} style={{opacity:0}} alt="logo_dmc" />

  return (
    <> 
      <div className={"navbarMobile" + props.className}> {/* Début de navbar responsive */}
        <div id="logoMobile">{logoHeader}</div>
        <div className="icone">
          <label htmlFor="burgerIcon">{iconMenuBurger}</label>
        </div>
        <input onChange={()=>""} onClick={handleClick} type="checkbox" id="burgerIcon" checked={iconBurgerIsOpen}/> {/* Checkbox caché pour changer d'icône et ouvrir le menu burger */}

        <nav className={"burgerMenu " + props.className}> {/* Apparait ou disparaît en fonction du clique sur le checkbox */}
          <Link to="/about" onClick={()=>setIconBurgerIsOpen(!iconBurgerIsOpen)}>À propos</Link>
          <Link to="/services" onClick={()=>setIconBurgerIsOpen(!iconBurgerIsOpen)}>Services</Link>
          <Link to="/avis" onClick={()=>setIconBurgerIsOpen(!iconBurgerIsOpen)}>Avis</Link>
          <Link className="logoNavbar" to="/" onClick={()=>setIconBurgerIsOpen(!iconBurgerIsOpen)}><img src={logoDMC} alt="Logo de desmettre maxime canin" /></Link>
          <Link to="/reserver" onClick={()=>setIconBurgerIsOpen(!iconBurgerIsOpen)}>Gardiennage</Link>
          <Link to="/tips" onClick={()=>setIconBurgerIsOpen(!iconBurgerIsOpen)}>Quelques conseils</Link>
          <Link to="/contact" onClick={()=>setIconBurgerIsOpen(!iconBurgerIsOpen)}>Contact</Link>
        </nav>
      </div>          
    </>
  )
}

export default Navbar