import React, { useState } from 'react'
import axios from 'axios'
import env from "react-dotenv";

const RegisterPage = () => {

    const [inputFirstName, setInputFirstName] = useState('')
    const [inputLastName, setInputLastName] = useState('')
    const [inputEmail, setInputEmail] = useState('')
    const [inputPhone, setPhone] = useState('')
    const [inputDogName, setInputDogName] = useState('')
    const [inputDogPhoto, setInputDogPhoto] = useState([])
    const [inputRace, setInputRace] = useState('')

    const handleFirstName = e => {
        setInputFirstName(e.target.value)
    }

    const handleLastName = e => {
        setInputLastName(e.target.value)
    }

    const handleEmail = e => {
        setInputEmail(e.target.value)
    }

    const handleTel = e => {
        setPhone(e.target.value)
    }

    const handleDogName = e => {
        setInputDogName(e.target.value)
    }

    const handleDogPhoto = e => {
        setInputDogPhoto(e.target.files[0])
    }

    const handleRace = e => {
        setInputRace(e.target.value)
    }

    const handleSubmit = async e => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("inputFirstName", inputFirstName)
        formData.append("inputLastName", inputLastName)
        formData.append("inputEmail", inputEmail)
        formData.append("inputPhone", inputPhone)
        formData.append("inputDogName", inputDogName)
        formData.append("inputDogPhoto", inputDogPhoto)
        formData.append("inputRace", inputRace)
        try {
            const response = await axios.post(`${env.REACT_APP_API_URL}user/register`, formData)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    console.log(inputDogPhoto.name)

    return (
        <>
            <form onSubmit={handleSubmit}>
                <label>Prénom</label>
                <input onChange={handleFirstName} type="text" />
                <label>Nom</label>
                <input onChange={handleLastName} type="text" />
                <label>Email</label>
                <input onChange={handleEmail} type="email" />
                <label>Téléphone</label>
                <input onChange={handleTel} type="tel" />
                <label>Nom de votre chien</label>
                <input onChange={handleDogName} type="text" />
                <label>Photo de votre chien</label>
                <input onChange={handleDogPhoto} type="file" />
                <label>Race</label>
                <input onChange={handleRace} type="text" />
                <input type='submit' value='Envoyer' />
            </form>
        </>
    )
}

export default RegisterPage