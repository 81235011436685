import React from "react";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import marleyFooter from "../assets/images/marleyFooter.webp";


const Footer = (props) => {
  return (
    <>
    <div className="namePage">
        <h2>{props.h2}</h2>
        <img src={marleyFooter} alt="dog_image" />
    </div>
      <nav className="footerNav">
        <li>
          <Link to="/">Accueil</Link>
        </li>
        <li>
          <Link to="/about">À propos</Link>
        </li>
        <li>
          <Link to="/services">Services</Link>
        </li>
        <li>
          <Link to="/chiens_dresses">chiens dressés</Link>
        </li>
        <li>
          <Link to="/avis">Avis</Link>
        </li>
        <li>
          <Link to="/">Réserver un créneau</Link>
        </li>
        <li>
          <Link to="/tips">Quelques conseils</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </nav>
      <div className="footerContact">
        <a
          href="https://www.facebook.com/maxime.desmettre"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-facebook"></i>
        </a>
        <a
          href="https://www.instagram.com/maximedesmettre/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-instagram"></i>
        </a>
        <a
          href="mailto:pierrocarlier@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-solid fa-envelope"></i>
        </a>
        <a href="tel:+33643831004" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <i className="fa-solid fa-phone"></i>
        </a>
      </div>
      <div className="footerConfidentialité">
        <Link to="/confidentialite">Politique de confidentialité</Link>&nbsp;
        <Link to="/">Mentions légales</Link>
      </div>
      <div className="footerCopyright">
        <p>Tous droits réservés &copy;2022 DMC</p>
        <p>
          Site réalisé par{" "}
          <a
            href="https://www.facebook.com/pierre.carlier.902"
            target="_blank"
            rel="noreferrer"
          >
            Pierre Carlier
          </a>
        </p>
      </div>
    </>
  );
};

export default Footer;
