import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import axios from "axios";
import Slideshow from "../components/Slide";
import dogTips from "../assets/images/dogTips.png";
import env from "react-dotenv";

// component qui affiche la liste des conseils
const TipsPage = (props) => {
  //ajout de className pour le css
  const [nameOfClass, setNameOfClass] = useState("");

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${env.REACT_APP_API_URL}tips/all`);
      setData(response.data);
    };
    fetchData();
    setIsLoading(false);
  }, []);

  return (
    <>
      <header>
        <Navbar className={nameOfClass} />
      </header>
      <div className="tipsContainer">
        <div id="tipsIntro">
          <div>
            <h2>Quelques conseils</h2>
            <p>
              Voici quelques conseils qui vous seront sans doute utiles ! Ce
              sont des choses que vous pouvez appliquer au quotidien très
              simplement.
            </p>
          </div>
          <img src={dogTips} alt="dog_image"></img>
        </div>
        <Slideshow />
        <footer>
          <Footer h2={props.h2} />
        </footer>
      </div>
    </>
  );
};

export default TipsPage;
