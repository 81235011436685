import React, { useState, useRef, useEffect } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import axios from "axios";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import env from "react-dotenv";


// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";

const Sittings = props => {

  //ajout de className pour le css
  const [nameOfClass, setNameOfClass] = useState('')

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${env.REACT_APP_API_URL}dogSittings/all`)
      setData(response.data)
    }
    fetchData()
    setIsLoading(false)
  }, [])

  const calendarComponentRef = useRef();
  let event = (
    data.map((i) => {
      return {
        title: "complet",
        start: i.dateStart,
        end: i.dateEnd
      }
    })
  )

  return (
    <>
      <header>
        <Navbar className={nameOfClass} />
      </header>
      <div className="reservationPage">
        <h2>{props.h2}</h2>
        <p>
          DMC vous propose un <span className="bold">service de gardiennage canin</span>. N'hésitez pas à nous contacter par l'un des moyens disponibles <span className="bold">si vos dates ne sont pas notées "complet"</span>.
        </p>
        <div className='iconesGardiennage'>
          <a
            href="https://www.facebook.com/maxime.desmettre"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-brands fa-facebook"></i>
          </a>
          <a
            href="https://www.instagram.com/maximedesmettre/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a
            href="mailto:pierrocarlier@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-solid fa-envelope"></i>
          </a>
          <a href="tel:+33643831004" target="_blank" rel="noopener noreferrer">
            <i className="fa-solid fa-phone"></i>
          </a>
        </div>
        <div className="reservations">
          <FullCalendar
            ref={calendarComponentRef}
            initialView="dayGridMonth"
            displayEventTime={false}
            selectable={true}
            plugins={[
              dayGridPlugin,
              interactionPlugin,
              timeGridPlugin,
              resourceTimeGridPlugin
            ]}
            headerToolbar={{
              start: "prev,next today",
              center: "title",
              end: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
            }}
            buttonText={{
              today: "Aujourd'hui",
              month: "Mois",
              week: "Semaine",
              day: "Jour",
              list: "Liste",
            }}
            locale='fr'
            eventBackgroundColor="#c88510"
            eventBorderColor="#c88510"
            events={event}
            height="650px"
          />
        </div>
        <footer>
          <Footer h2={props.h2} />
        </footer>
      </div>
    </>
  );
}
export default Sittings;