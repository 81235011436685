import React, { useEffect, useState } from 'react'
import rating1 from '../assets/images/rating1.webp'
import rating2 from '../assets/images/rating2.webp'
import rating3 from '../assets/images/rating3.webp'
import rating4 from '../assets/images/rating4.webp'
import rating5 from '../assets/images/rating5.webp'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import axios from 'axios'
import doubleQuote from '../assets/images/doubleQuote.svg'
import logoDMC from '../assets/images/logoDMC.webp'
import env from "react-dotenv";


const Customer_feedbacks = props => {

  //ajout de className pour le css
  const [nameOfClass, setNameOfClass] = useState('')

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  //useEffect pour récupérer la liste des feedbacks
  useEffect(() => {
    const fetchData = async () => {
      try{
        const response = await axios.get(`${env.REACT_APP_API_URL}feedbacks/all`)
      setData(response.data)
      }catch(error){
        console.log(error.response.data)
      }
      
    }
    fetchData()
    setIsLoading(false)
  }, [])

  const [inputComment, setInputComment] = useState("")
  const [inputNote, setInputNote] = useState("")
  const [emailForFeedback, setEmailForFeedback] = useState("")

  const handleComment = e => {
    setInputComment(e.target.value)
  }

  const handleNote = e => {
    setInputNote(e.target.value)
  }

  const handleEmail = e => {
    setEmailForFeedback(e.target.value)
  }


  const handleSubmit = async () => {

    const formData = new FormData()
    formData.append("inputComment", inputComment)
    formData.append("inputNote", inputNote)

    try {
      const response = await axios.post(`${env.REACT_APP_API_URL}register`, formData)
      console.log(response)
    } catch (error) {
      console.log(error.response.data)
    }

  }

  const ratingImages = {
    'rating1': rating1,
    'rating2': rating2,
    'rating3': rating3,
    'rating4': rating4,
    'rating5': rating5,
  }
  return (
    <>
      <header>
        <Navbar className={nameOfClass} />
      </header>
      <div className='feedbacksContainer'>
        <div className='feedbacksPart1'>
          <h2 id='h2feedbacks'><span className="highlight">{props.h2}</span></h2>
          <p className='intro'>De nombreuses personnes nous ont déjà fait confiance. Voici leurs avis 😃. Vous pouvez également commenter si vous avez déjà suivi un cours chez nous et que vous êtes connecté !</p>
          {/* <img src={marley} alt="marleyLunettes" id="marleyLunettes" /> */}
        </div>
        <div id='cardsFeedbacksContainer'>
          {data.map((i, index) => {
            return (
              <div className='feedbackCards' key={index}>
                <div id="feedbackCardsTop">
                  <img id="doubleQuote" src={doubleQuote} alt="guillemets"></img>
                  <img id="logoDMC" src={logoDMC} alt="logo_dmc"></img>
                </div>
                <blockquote>
                  <p>
                    {i.comment}
                  </p>
                </blockquote>
                <figure>
                  <img className='stars' src={ratingImages["rating" + i.note]} alt="notes"></img>
                  <figcaption> NOM DU PROPRIO, propriétaire de NOM DU CHIEN </figcaption>
                </figure>
              </div>
            )
          })}
        </div>
      </div>
      <div>
        <form onSubmit={handleSubmit}>
          {/* <label>Votre email</label>
          <input value={emailForFeedback} onChange={emailForFeedback} type="email"/> */}

          <label>Votre commentaire</label>
          <input value={inputComment} onChange={handleComment} type="text" />

          <label>Votre note entre 1 et 5</label>
          <input value={inputNote} onChange={handleNote} type="number" min="1" max="5" />

          <input type='submit' value='Envoyer' />
        </form>
      </div>
      <footer>
        <Footer h2={props.h2} />
      </footer>
    </>
  )
}

export default Customer_feedbacks