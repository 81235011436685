import React, { useState, useEffect } from "react";
import axios from "axios";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import env from "react-dotenv";

const Slideshow = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${env.REACT_APP_API_URL}tips/all`);
      setData(response.data);
    };
    fetchData();
    setIsLoading(false);
  });

  const spanStyle = {
    padding: "20px",
    background: "#efefef",
    color: "#000000",
  };

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    height: "400px",
  };

  const buttonStyle = {
    width: "30px",
    background: "none",
    border: "0px",
    color: "black",
  };

  const responsiveSettings = [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ];

  const properties = {
    prevArrow: (
      <button style={{ ...buttonStyle }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#fff"
        >
          <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" />
        </svg>
      </button>
    ),
    nextArrow: (
      <button style={{ ...buttonStyle }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#fff"
        >
          <path d="M512 256L270 42.6v138.2H0v150.6h270v138z" />
        </svg>
      </button>
    ),
  };

  let backgroundImg;
  return (
    <div className="slide-container">
      <Slide slidesToShow={1} {...properties} responsive={responsiveSettings}>
        {data.map((i, index) => (
          <div
            key={index}
            style={{ ...divStyle, backgroundImage: `url(${i.illustration})` }}
          >
            <span style={spanStyle}>{i.title}</span>
            <span style={spanStyle}>{i.content}</span>
          </div>
        ))}
      </Slide>
    </div>
  );
};

export default Slideshow;
