import React, { useState} from 'react'
import about2 from '../assets/images/about2.webp'
import Experiences from '../components/Experiences'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

const AboutPage = (props) => {

    //ajout de className pour le css
    const [nameOfClass, setNameOfClass] = useState('')

  return (
    <>
      <header>
        <Navbar className={nameOfClass} />
      </header>
      <div className='about'>
        <h2>{props.h2}</h2>
        <div className='aboutCard'>  
          <img src={about2} alt="photo_about" />
          <div className='aboutCardTexts'>
            <p><span className='titlesIdentityCard'>Nom</span> Maxime Desmettre</p> 
            <p><span className='titlesIdentityCard'>Passion</span> Dressage canin, depuis plus de 10 ans</p>
            <p><span className='titlesIdentityCard'>Propriétaire de</span> 2 malinois, 1 dogue de Bordeaux, 1 bulldog français et 1 rottweiler</p>
            <p><span className='titlesIdentityCard'>Méthodes</span> répétition, régularité, adaptation</p>
          </div>
        </div>
        <Experiences />
        <footer>
          <Footer h2={props.h2}/>
        </footer>
      </div>
    </>
  )
}

export default AboutPage