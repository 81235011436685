import React from "react";
import "./App.css";
import RegisterPage from "./containers/RegisterPage";
import ContactPage from "./containers/ContactPage";
import HomePage from "./containers/HomePage";
import ServicesPage from "./containers/ServicesPage";
import AboutPage from "./containers/AboutPage";
import Customer_feedbacks from "./containers/CustomersFeedbacks";
import Sittings from "./containers/Sittings";
import TipsPage from "./containers/TipsPage";

import { Routes, Route } from "react-router-dom";

function App() {
  
  return (
    <div className="App">
      
      <Routes>
        <Route path="/about" element={<AboutPage h2="À propos" />} />
        <Route path="/services" element={<ServicesPage h2="Services" />} />
        <Route path="/avis" element={<Customer_feedbacks h2="Avis" />} />
        <Route path="/reserver" element={<Sittings h2="Gardiennage" />} />
        <Route path="/register" element={<RegisterPage h2="S'enregistrer" />} />
        <Route path="/tips" element={<TipsPage h2="Quelques conseils" />} />
        <Route path="/contact" element={<ContactPage h2="Contact" />} />
        <Route path="/" element={<HomePage h2="Accueil" />} />
      </Routes>
    </div>
  );
}

export default App;
