import React, { useEffect, useState } from 'react'
import axios from 'axios'
import env from "react-dotenv";

const TrainedDogsPage = (props) => {

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${env.REACT_APP_API_URL}educations/all`)
      setData(response.data)
    }
    fetchData()
    setIsLoading(false)
  }, [])

  return (
      <div className='trainedDogs'>
        <div className="trainedDogsTablette">
          <p className='introTrainedDogs'>
            Voici des <span className='bold'>prises en charge réelles </span>de chiens par DMC. Celles-ci vous donnent un <span className='bold'>aperçu</span> du travail que nous réalisons. Évidemment, il est préférable de <span className='bold'>continuer le travail chez soi</span>, en autonomie.
          </p>
          <div id="trainedDogsDetails">
            {data.map((i) => {
              return (
                <details key={i.id} >
                  <summary>
                    <h3>{i.problemDescription}</h3>
                    <div className="plus">+</div>
                    <div className="moins">-</div>
                  </summary>
                  <p>{i.solutions}</p>
                  <p className='duration'>Le problème fut réglé en {i.duration} de cours</p>
                </details>
              )
            })}
          </div>
        </div>
      </div>
  )
}

export default TrainedDogsPage