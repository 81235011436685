import React, { useEffect, useState, useRef } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useFormik } from "formik";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import env from "react-dotenv";

const ContactPage = (props) => {
  //ajout de className pour le css
  const [nameOfClass, setNameOfClass] = useState("");

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      to_name: `${env.REACT_APP_EMAILJS_ADMIN_EMAIL}`,
      email: "",
      phone: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(15, "Merci d'entrer moins de 15 caractères")
        .required("Merci d'indiquer votre prénom"),
      lastName: Yup.string()
        .max(20, "Merci d'entrer moins de 20 caractères")
        .required("Merci d'indiquer votre nom"),
      email: Yup.string()
        .email("Adresse email invalide")
        .required("Merci d'indiquer votre email"),
      phone: Yup.string().required(
        "Merci d'indiquer votre numéro de téléphone"
      ),
      subject: Yup.string()
        .max(50, "Merci d'entrer moins de 50 caractères")
        .required("Merci d'indiquer le sujet de votre message"),
      message: Yup.string("Invalid phone number")
        .min(15, "Merci d'écrire au moins 15 caractères")
        .required("Merci d'écrire un message"),
    }),
  });

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(`${env.REACT_APP_EMAILJS_SERVICE_ID}`, `${env.REACT_APP_EMAILJS_TEMPLATE_ID}`, form.current, `${env.REACT_APP_EMAILJS_PUBLIC_KEY}`)
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
    {console.log(`service: ${process.env.REACT_APP_EMAILJS_SERVICE_ID}`)}
      <header>
        <Navbar className={nameOfClass} />
      </header>
      <div className="contactPage">
        <h2>{props.h2}</h2>
        <form ref={form} onSubmit={sendEmail}>
          <input
            id="firstName"
            name="firstName"
            type="text"
            placeholder="Prénom"
            onChange={formik.handleChange}
            value={formik.values.firstName}
          />
          {formik.touched.firstName && formik.errors.firstName ? (
            <div>{formik.errors.firstName}</div>
          ) : null}
          <input
            id="lastName"
            name="lastName"
            type="text"
            placeholder="Nom"
            onChange={formik.handleChange}
            value={formik.values.lastName}
          />
          {formik.touched.lastName && formik.errors.lastName ? (
            <div>{formik.errors.lastName}</div>
          ) : null}
          <input
            id="email"
            name="email"
            type="email"
            placeholder="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div>{formik.errors.email}</div>
          ) : null}
          <input
            id="phone"
            name="phone"
            type="phone"
            placeholder="Téléphone"
            onChange={formik.handleChange}
            value={formik.values.phone}
          />
          {formik.touched.phone && formik.errors.phone ? (
            <div>{formik.errors.phone}</div>
          ) : null}
          <input
            id="subject"
            name="subject"
            type="text"
            placeholder="Sujet"
            onChange={formik.handleChange}
            value={formik.values.subject}
          />
          {formik.touched.subject && formik.errors.subject ? (
            <div>{formik.errors.subject}</div>
          ) : null}
          <textarea
            rows="10"
            id="message"
            name="message"
            placeholder="Message"
            onChange={formik.handleChange}
            value={formik.values.message}
          />
          {formik.touched.message && formik.errors.message ? (
            <div>{formik.errors.message}</div>
          ) : null}
          <input disabled={formik.isSubmitting} type="submit" value="envoyer" />
        </form>
      </div>
      <footer>
        <Footer h2={props.h2} />
      </footer>
    </>
  );
};

export default ContactPage;
